import React from 'react';
import downArrow from "../assets/down-arrow.svg";

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'; 
import Col from 'react-bootstrap/Col';

import Section from './Section';

import PropTypes from "prop-types";

class FeatureSection extends React.Component {

  constructor(props) {
    super(props);
    this.renderImage = this.renderImage.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderText = this.renderText.bind(this);
  }

  renderImage() {
    return (
      <Col className="col-sm-bottompadded" md={"auto"}>
        <img className={"screenshot"} alt={"App Screenshot"} src={this.props.image}/>
      </Col>
    )
  }

  renderContent() {
    if (this.props.flipOrientation) {
      return (
        <Row>
          {this.renderImage()}
          <Col/>
          {this.renderText()}
        </Row>
      )
    } else {
      return (
        <Row> 
          {this.renderText()}
          <Col/>
          {this.renderImage()}
        </Row>
      )
    }
  }

  renderText() {
    return (
      <Col className="my-auto" md={6}>
        <Row className="align-items-center" noGutters={true} style={{"margin-bottom": 44, padding: 0}}>
          {!!this.props.icon && (
            <Col style={{padding: 0}} md={"auto"}>
              <img alt={"Icon"} className={"icon"} src={this.props.icon}/>
            </Col>
          )}
          <Col style={{pagging: 0}} className="text-md-left">
            <h1 style={{color: this.props.tintColor}}>{this.props.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="text-md-left" style={{padding: 0}}>
            <h3 style={{color: this.props.tintColor, "margin-bottom": 28}}>{this.props.subtitle}</h3>
            {!!this.props.buttons && this.props.buttons.map(button => {
              return <Button href={button.href} variant={button.variant}>{button.title}</Button>
            })}
          </Col>
        </Row>
      </Col>
    )
  }

  render() {
    return (
      <Section backgroundColor={this.props.backgroundColor}>
        {this.renderContent()}
        {this.props.showDownIndicator && (
          <div style={{"position": "relative", bottom: -66, left: 0, right: 0}}>
            <Col>
              <img alt={"Down Arrow"} tag={"down-arrow"} src={downArrow}/>
            </Col>
          </div>
        )}
      </Section>
    )
  }
}

FeatureSection.defaultProps = {
  backgroundColor: "#fff",
  tintColor: "#212433"
}

FeatureSection.propTypes = {
  /**
   * The background colour of the section
   */
  backgroundColor: PropTypes.string,

  /**
   * An array of buttons to render
   */
  buttons: PropTypes.arrayOf(PropTypes.shape({
    variant: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  })),

  /**
   * Whether to flip the orientation
   */
  flipOrientation: PropTypes.bool,

  /**
   * The src for the icon
   */
  icon: PropTypes.any,

  /**
   * The src for the image
   */
  image: PropTypes.any,

  /**
   * The subtitle for the section
   */
  subtitle: PropTypes.string.isRequired,

  /**
   * Whether to show the scroll down indicator
   */
  showDownIndicator: PropTypes.bool,

  /**
   * The title of the section
   */
  title: PropTypes.string.isRequired,

  /**
   * The colour for tinting elements such as text
   */
  tintColor: PropTypes.string
}

export default FeatureSection;

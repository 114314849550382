import React from 'react';

import Section from "../components/Section";
import Header from "../components/Header";
import Footer from "../components/Footer";

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import simon from "../assets/simon.png";

import wonderblyIcon from "../assets/icon-wonderbly.png";
import lushIcon from "../assets/icon-lush.png";
import pixieIcon from "../assets/icon-pixie.png";
import faIcon from "../assets/icon-fa.png";
import emergencyIcon from "../assets/icon-emergency.png";
import rfuIcon from "../assets/icon-rfu.png";
import rspbIcon from "../assets/icon-rspb.png";
import bloodIcon from "../assets/icon-blood.png";

var Web3 = require('web3');

const BTC_KEY = "bc1q9z4agdpkntqlhq40lymqw3qzx3u6zyh5kjrmzp";
const ETH_KEY = "0xE42e7B5ad91fE5EFeaA2403A4809321bB28cdc7C";

class About extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      web3: false,
      ethWallet: null
    }
  }

  setupWeb3 = async () => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
      this.setState({
        web3: true
      });
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      // Use Mist/MetaMask's provider.
      this.web3 = window.web3;
      this.setState({
        web3: true
      });
    }
    // Do nothing for now...
  }

  getAccounts = () => {

  }

  componentDidMount() {
    this.setupWeb3();  
  }

  onClickCopy = (value) => {

  };

  takePayment = async(accounts, address) => {

    this.setState({takingPayment: true})

    if (accounts.length === 0) {
      return;
    }

    try {
      await this.web3.eth.sendTransaction({
        from: accounts[0],
        to: address
      })
      this.setState({takingPayment: false, supported: true});
    } catch(err) {
      this.setState({takingPayment: false, supported: false});
      throw err;
    }
  };

  getAccounts = async () => {
    try {
      var accounts = await this.web3.eth.getAccounts();
      return accounts;
    } catch(err) {
      throw err;
    }
  }

  onSendETH = async (address) => {

    if (window.ethereum) {
      try {
        this.setState({awaitingWeb3Permission: true});
        await window.ethereum.enable();
        let accounts = await this.getAccounts();
        this.setState({awaitingWeb3Permission: false});
        this.takePayment(accounts, address);
      } catch {
        this.setState({
          awaitingWeb3Permission: false,
          takingPayment: false
        })
      }
    } else {
      try {
        let accounts = await this.getAccounts();
        this.takePayment(accounts, address);
      } catch {
        this.setState({
          awaitingWeb3Permission: false,
          takingPayment: false
        });
      }
    }
  };

  render() {

    return (
      <div className="about" style={{textAlign: "center"}}>
        <Header history={this.props.routerProps.history} title={"Camrote"}/>
        <Section 
          backgroundColor={"#36D1DC"}
          verticalPadding={88}
          style={{textAlign: "center"}}
        > 
          <h1 style={{color: "#FFF"}}>Hi, I'm Simon</h1>
          <img src={simon} style={{width: 222, height: 222, marginTop: 44}}/>
        </Section>
        <Section verticalPadding={88}>
          <p>I'm an iOS developer with over 7 years experience, and the creator and – lone – developer of Camrote. In my time developing on the iOS platform I've worked on many apps, including...</p>
          <div className={"iconContainer"}>
            <div>
              <Row className="justify-content-md-center">
                <Col xs={2} className={"d-md-none"}/>
                <Col xs={4} md={3} lg={2} className={"mb-4"}>
                  <img alt={"Wonderbly Story Time"} className={"appIcon img-fluid"} src={wonderblyIcon}/>
                </Col>
                <Col xs={4} md={3} lg={2} className={"mb-4"}>
                  <img alt={"LUSH Labs"} className={"appIcon img-fluid"} src={lushIcon}/>
                </Col>
                <Col xs={2} className={"d-md-none"}/>
                <Col xs={2} className={"d-md-none"}/>
                <Col xs={4} md={3} lg={2} className={"mb-4"}>
                  <img alt={"Pixie"} className={"appIcon img-fluid"} src={pixieIcon}/>
                </Col>
                <Col xs={4} md={3} lg={2} className={"mb-4"}>
                  <img alt={"First Aid by American Red Cross"} className={"appIcon img-fluid"} src={faIcon}/>
                </Col>
                <Col xs={2} className={"d-md-none"}/>
              </Row>
              <Row className="justify-content-md-center">
                <Col xs={2} className={"d-md-none"}/>
                <Col xs={4} md={3} lg={2} className={"mb-4"}>
                  <img alt={"Emergency by American Red Cross"} className={"appIcon img-fluid"} src={emergencyIcon}/>
                </Col>
                <Col xs={4} md={3} lg={2} className={"mb-4"}>
                  <img alt={"RFU 02 Touch"} className={"appIcon img-fluid"} src={rfuIcon}/>
                </Col>
                <Col xs={2} className={"d-md-none"}/>
                <Col xs={2} className={"d-md-none"}/>
                <Col xs={4} md={3} lg={2} className={"mb-4"}>
                  <img alt={"Blood Donor"} className={"appIcon img-fluid"} src={bloodIcon}/>
                </Col>
                <Col xs={4} md={3} lg={2} className={"mb-4"}>
                  <img alt={"Giving Nature a Home"} className={"appIcon img-fluid"} src={rspbIcon}/>
                </Col>
                <Col xs={2} className={"d-md-none"}/>
              </Row>
            </div>
          </div>
          <p>I began working on Camrote in 2018 after realising I had the know-how and documentation to build an alternative to Sony's own remote-control app. My original aim for Camrote was to create something simple and useful for myself, but this quickly turned into a grander goal</p>
          <h3 style={{textAlign: "center", marginTop: 44, marginBottom: 44}}>"To build a remote control app for all skill levels, to help as many photographers as possible achieve their vision."</h3>
          <p>I develop Camrote from my home in west Wales, with my dog for company; and motivation, advice, and love from my wonderful wife. Camrote is a polished tool, but I'm doing my best to constantly improve it with new – and useful – functionality.</p>
          <p>I hope you enjoy using the app, please get in contact to show me anything cool which Camrote has helped you create, I love seeing what I've helped users shoot!</p>
        </Section>
        <Section 
          verticalPadding={88}
          backgroundColor={"#36DCA6"}
          style={{textAlign: "center"}}
          id={"support"}
        >
          <h2 style={{color: "#FFF"}}>Support Camrote</h2>
          <p style={{color: "#FFF"}}>All donations are greatly appreciated and will be used solely for the improvement of Camrote.</p>
          <p style={{color: "#FFF"}}>BTC <code onClick={this.onClickCopy.bind(this, BTC_KEY)} style={{marginLeft: 6}}>{BTC_KEY}</code> </p>
          <p style={{color: "#FFF"}}>ETH <code onClick={this.onClickCopy.bind(this, ETH_KEY)} style={{marginLeft: 6}}>{ETH_KEY}</code> </p>
          {this.state.web3 && (
            <Button disable={this.state.awaitingWeb3Permission || this.state.takingPayment} variant={"light"} onClick={this.onSendETH.bind(this, ETH_KEY)}>{this.state.awaitingWeb3Permission ? "Awaiting Connection" : "Donate ETH"}</Button>
          )}
          {this.state.supported && (
            <h4 style={{color: "#FFF"}}>Thank you for your support!</h4>
          )}
        </Section>
        <Footer/>
      </div>
    );
  }
}

export default About;

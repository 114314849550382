import React from 'react';

import PropTypes from "prop-types";


const styles = {
  container: {
    backgroundColor: '#F6F6F6',
    border: '1px solid #979797',
    borderRadius: 8,
    marginTop: 20
  },
  featureContainer: {
  	margin: 8
  },
  featuresContainer: {
  	padding: 12,
  	borderLeft: '1px solid #979797'
  },
  indicator: {
  	width: 18,
  	height: 18,
  	marginRight: 8,
  	borderRadius: 9,
  	backgroundColor: 'yellow'
  },
  'indicator-0': {
  	backgroundColor: '#DC3636',
  	border: '1px solid #AD1F1F'
  },
  'indicator-1': {
  	backgroundColor: '#36DCA6',
  	border: '1px solid #1FAD7F'
  },
  modelContainer: {
  	padding: 12
  }
}

class CameraModel extends React.Component {

  render() {
    return (
      <div className={"d-flex flex-row align-items-center justify-content-start"} style={styles.container}>
      	<div style={styles.modelContainer}>
      		<h5 style={{marginBottom: 0}}>{this.props.camera.model} {this.props.camera.note && <sup>{this.props.camera.note}</sup>}</h5>
      	</div>
      	<div className={"d-flex flex-row flex-wrap"} style={styles.featuresContainer}>
      		{
      			Object.keys(this.props.camera.features).map((key, index) => {
      				const feature = this.props.camera.features[key];
      				return (
      					<div style={styles.featureContainer} className={"d-flex flex-row align-items-center justify-content-start"} key={index}>
      						<div style={{
      							...styles.indicator,
      							...styles[`indicator-${feature.status}`]
      						}}/>
      						<h5 style={{marginBottom: 0}}>{key}{feature.note && <sup>{feature.note}</sup>}</h5>
      					</div>
      				);
      			})
      		}
      	</div>
      </div>
    )
  }
}

CameraModel.defaultProps = {

}

CameraModel.propTypes = {
  camera: PropTypes.any
}

export default CameraModel;

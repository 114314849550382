import React from 'react';

import CameraModel from '../components/CameraModel';
import Form from 'react-bootstrap/Form';
import Footer from "../components/Footer";
import Header from "../components/Header";
import Section from "../components/Section";
import { Link } from 'react-router-dom';

const CAMERAS = [
  {
    model: "a7iii",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "RX100 VII",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a7riv",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a7",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 0
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a5000",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 0
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a5100",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a6000",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1,
        note: 4
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 0
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a6300",
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1,
        note: 4
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a6500",
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1,
        note: 4
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a6400",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "a6600",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "a7r",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 0
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a7ii",
    features: {
      "RAW Transfer": {
        status: 1,
        note: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a7s",
    features: {
      "RAW Transfer": {
        status: 1,
        note: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a7sii",
    features: {
      "RAW Transfer": {
        status: 1,
        note: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a7rii",
    features: {
      "RAW Transfer": {
        status: 1,
        note: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a7riii",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "a9 (Firmware <= 4)",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "a9 (Firmware > 4)",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "a9ii",
    note: 9,
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "NEX-5R",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 0
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "NEX-5T",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 0
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "NEX-6",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 0
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "DSC-HX60/V",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "DSC-HX80",
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "DSC-HX90/V",
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "DSC-HX400/V",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "DSC-WX500",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "RX1R II",
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 0
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "RX10 II",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "RX10 III",
    features: {
      "RAW Transfer": {
        status: 1,
        note: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "RX100 III",
    features: {
      "RAW Transfer": {
        status: 1,
        note: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "RX100 IV",
    features: {
      "RAW Transfer": {
        status: 1,
        note: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "RX100 V",
    features: {
      "RAW Transfer": {
        status: 1,
        note: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "RX100 VI",
    note: 5,
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1,
        note: 3
      },
      "Movie Recording": {
        status: 1,
        note: 2
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "HDR-AS15",
    note: 6,
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 0
      },
      "Timelapse": {
        status: 0
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 0
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 0
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "HDR-AS20",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 0
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 0
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 0
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "HDR-AS30/V",
    note: 6,
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 0
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 0
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 0
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "HDR-AS100/V",
    note: 6,
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 0
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 0
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 0
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "HDR-AZ1",
    note: 6,
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 0
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "HDR-AS200V",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1,
        note: 7
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "FDR-X1000V",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1,
        note: 7
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "HDR-AS50",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1,
        note: 7
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "HDR-AS300",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1,
        note: 7
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "FDR-X3000",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 1,
        note: 7
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "HDR-MV1",
    features: {
      "RAW Transfer": {
        status: 0
      },
      "Exposure Controls": {
        status: 0
      },
      "Timelapse": {
        status: 0
      },
      "Touch Auto-Focus": {
        status: 0
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 0
      },
      "Still Transfer": {
        status: 0
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 0
      }
    }
  },
  {
    model: "ILCE-QX1",
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "DSC-QX30",
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 1
      },
      "Movie Transfer": {
        status: 1
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "DSC-QX10",
    note: 6,
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1,
        note: 8
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 0
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  },
  {
    model: "DSC-QX100",
    note: 6,
    features: {
      "RAW Transfer": {
        status: 1
      },
      "Exposure Controls": {
        status: 1
      },
      "Timelapse": {
        status: 1
      },
      "Touch Auto-Focus": {
        status: 1
      },
      "Image Review": {
        status: 1
      },
      "Focus Frames": {
        status: 1
      },
      "Still Transfer": {
        status: 0
      },
      "Movie Transfer": {
        status: 0
      },
      "Movie Recording": {
        status: 1
      },
      "Zoom": {
        status: 1
      }
    }
  }
]

Object.filter = function( obj, predicate) {
    let result = {}, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key) && !predicate(obj[key])) {
            result[key] = obj[key];
        }
    }
    return result;
};

const NOTES = {
  1: "\"Send to Smartphone\" does not support RAW transfer, you must use the \"Smart Remote Control\" app to transfer RAW images",
  2: "To enter video recording mode the physical mode dial on your camera must be set to Movie",
  3: "AVCHD movie is not supported",
  4: "Please make sure the \"Smart Remote Control\" app is fully up to date on your camera to enable this",
  5: "Support for the RX100 VI is not documented by Sony, but due to it's similar release date to the a7iii we are assuming it supports the same feature set.",
  6: "The latest firmware update is needed",
  7: "Only exposure compensation and white balance are controllable",
  8: "Only exposure compensation, ISO and white balance are controllable",
  9: "We are investigating a crash on the a9ii caused by Sony's latest firmware update"
}

class CameraSupport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleSearchTermChanged = (event) => {
    console.log("Search term", event.target.value);
    this.setState({
      searchTerm: event.target.value
    });
  };

  render() {

    let cameras = [...CAMERAS];
    cameras.sort((a, b) => {
      var modelA = a.model.toUpperCase(); // ignore upper and lowercase
      var modelB = b.model.toUpperCase(); // ignore upper and lowercase
      if (modelA < modelB) {
        return -1;
      }
      if (modelA > modelB) {
        return 1;
      }
      // models must be equal
      return 0;
    });
    if (this.state.searchTerm && this.state.searchTerm.trim().length > 0) {
      cameras = cameras.filter(camera => {
        // -1 returned if no result found
        return camera.model.search(this.state.searchTerm) !== -1;
      })
    }

    let notes = {...NOTES};
    const allFeatures = cameras.flatMap(camera => {
      return Object.values(camera.features);
    });
    const featureNotes = allFeatures.filter(feature => {
      return feature.note !== undefined;
    }).map(feature => {
      return feature.note;
    })

    const cameraNotes = cameras.filter(camera => {
      return camera.note !== undefined;
    }).map(camera => {
      return camera.note
    })

    const allNotes = featureNotes.concat(cameraNotes).sort().filter((v, i, a) => a.indexOf(v) === i);

    return (
      <div>
        <Header history={this.props.routerProps.history} title={"Support"}/>
        <Section 
          verticalPadding={88}
        > 
          <h2 style={{textAlign: 'center', marginBottom: 20}}>Camera Support</h2>
          <h5 style={{textAlign: 'center'}}> 
            Search below to see which Camrote features are supported by your camera
          </h5>
          <Form>
            <Form.Control onChange={this.handleSearchTermChanged} placeholder={"Enter camera model"} style={{textAlign: 'center', marginTop: 22, border: '1px solid #979797', borderRadius: 8}}></Form.Control>
          </Form>
          <hr/>
          <React.Fragment>
            {
              cameras.map((camera, index) => {
                return <CameraModel key={index} camera={camera}/>
              })
            }
          </React.Fragment>
          <div style={{marginTop: 22}}>
            {allNotes.length > 0 && (
              allNotes.map((note, index) => {
                return <p key={index} style={{textAlign: 'center', color: "rgb(96, 108, 113)"}}>{note}. {notes[note]}</p>
              })
            )}
          </div>
          <h3 style={{color: "#1699A2", marginBottom: 22, marginTop: 64, textAlign: "center"}}>Something Incorrect?</h3>
          <p style={{color: "rgb(96, 108, 113)"}}>This list is taken from Sony's official documentation, but some models aren't included on that so we have to go on user-reports. If you know something does/doesn't work, please <Link to="/contact">contact us</Link> and we will update this page.</p>
        </Section>
        <Footer/>
      </div>
    );
  }
}

export default CameraSupport;

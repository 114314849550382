import React from 'react';

import Section from "../components/Section";
import Header from "../components/Header";
import Footer from "../components/Footer";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      validated: false,
      sent: false,
      errored: false
    }
  }

  handleSubmit(event) {

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({validated: true});
      return;
    }

    this.setState({
      sending: true,
      sent: false,
      errored: false,
      validated: false
    });
    event.preventDefault();

    // https://uguwfvwmrd.execute-api.us-east-1.amazonaws.com/dev/email/send
    // Prepare data to send
    const data = {};
    const formElements = Array.from(form);
    formElements.map(input => (data[input.name] = input.value));

    // Construct an HTTP request
    var xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action, true);
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    // Send the collected data as JSON
    xhr.send(JSON.stringify(data));

    // Callback function
    xhr.onloadend = response => {
      if (response.target.status === 200) {
        // The form submission was successful
        form.reset();
        this.setState({
          sent: true,
          sending: false
        });
        // formResponse.innerHTML = 'Thanks for the message. I’ll be in touch shortly.';
      } else {
        // The form submission failed
        // formResponse.innerHTML = 'Something went wrong';
        console.error(JSON.parse(response.target.response).message);
        this.setState({
          errored: true,
          sending: false
        });
      }
    };
  }

  render() {
    return (
      <div className="contact">
        <Header history={this.props.routerProps.history} title={"Camrote"}/>
        <Section 
          verticalPadding={88}
        > 
          <h2>
          Contact Us
          </h2>
          <p style={{marginBottom: 32}}> 
          Any questions, feature ideas, other opportunities? We love hearing from our users!
          </p>
          <Form 
            style={{
              padding: 20, 
              borderRadius: 8, 
              borderWidth: 2, 
              borderColor: "#F6F6F6",
              borderStyle: "solid"
            }}
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSubmit}
            method="POST"
            action="https://uguwfvwmrd.execute-api.us-east-1.amazonaws.com/dev/email/send"
          >
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control required type="text" name="name" placeholder="Enter name"/>
              <Form.Control.Feedback type="invalid">
                Please enter your name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formGroupEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control required type="email" name="reply_to" placeholder="Enter email"/>
              <Form.Control.Feedback type="invalid">
                Please a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formGroupMessage">
              <Form.Label>Subject</Form.Label>
              <Form.Control as="select" name="subject" required type="text">
                <option>General</option>
                <option>Support</option>
                <option>Feature Suggestion</option>
                <option>Press Opportunity</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formGroupMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" name="message" required type="text"/>
              <Form.Control.Feedback type="invalid">
                Please enter a message!
              </Form.Control.Feedback>
            </Form.Group>
            <Button disabled={this.state.sending} variant="dark" type="submit">
              Submit
            </Button>
            <Alert show={this.state.sent} style={{marginBottom: 0, marginTop: 24}} variant={"success"}>
              Message sent! I will respond to you as soon as possible.
            </Alert>
            <Alert show={this.state.errored} style={{marginBottom: 0, marginTop: 24}} variant={"danger"}>
              Failed to send, please send us an email: support@camrote.app.
            </Alert>
          </Form>
        </Section>
        <Footer/>
      </div>
    );
  }
}

export default Contact;

import React from 'react';
import Container from 'react-bootstrap/Container';

import PropTypes from "prop-types";

class Section extends React.Component {

  render() {

    const passProps = {...this.props};
    delete passProps.backgroundColor;
    delete passProps.verticalPadding;

    return (
      <div {...passProps} className={"section"} style={Object.assign({backgroundColor: this.props.backgroundColor}, this.props.style)}>
        <Container style={{padding: `${this.props.verticalPadding}px 28px`}}>
          {this.props.children}
        </Container>
      </div>
    )
  }
}

Section.defaultProps = {
  backgroundColor: "#fff",
  verticalPadding: 100
}

Section.propTypes = {
  /**
   * The background colour of the section
   */
  backgroundColor: PropTypes.string,

  /**
   * The vertical padding to apply to the section
   */
  verticalPadding: PropTypes.number,
}

export default Section;

import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Header from "../components/Header";

class App extends React.Component {

  render() {
    return (
      <div>
        <Header history={this.props.routerProps.history} title={"Privacy Policy"}/>
        <Container style={{"padding": 44}}>
          <Alert style={{marginBottom: 44}} variant={"warning"}>
          	This privacy policy applies to all information collected by the Camrote app for iOS.
          </Alert>
        	<h3 style={{color: "#1699A2", marginBottom: 22}}>Information we collect</h3>
        	<h4 style={{color: "#1699A2"}}>Analytics</h4>
        	<p style={{color: "rgb(96, 108, 113)"}}>The only information collected by default in the app is <strong>anonymous</strong> analytics data, this includes your timezone and a rough location based on your IP address, but cannot at any point be used to identify you.</p>
            <p style={{color: "rgb(96, 108, 113)"}}>Specifically we collect:</p>
        	<ul>
        		<li style={{color: "rgb(96, 108, 113)"}}>Data about which cameras are used with Camrote.</li>
        		<li style={{color: "rgb(96, 108, 113)"}}>Data about which features are used within Camrote.</li>
        		<li style={{color: "rgb(96, 108, 113)"}}>Data about which devices Camrote is used on.</li>
        		<li style={{color: "rgb(96, 108, 113)"}}>Crash reports if Camrote should crash whilst in use.</li>
        	</ul>
            <p style={{color: "rgb(96, 108, 113)"}}>This data is stored by a 3rd party in accordance with the privacy policy here.</p>
        	<h3 style={{color: "#1699A2", marginBottom: 22, marginTop: 22}}>Information 3rd parties collect</h3>
            <p style={{color: "rgb(96, 108, 113)"}}>If you choose to give feedback or submit a bug report and provide your email address, this is stored securely by our feedback provider <a href="https://instabug.com/">Instabug</a> and is not stored by ourselves or provided to any other parties.</p>
            <h3 style={{color: "#1699A2", marginBottom: 22, marginTop: 22}}>Information Usage</h3>
            <p style={{color: "rgb(96, 108, 113)"}}>The information we collect is used exclusively to improve Camrote as a product, and to provide customer support.</p>
            <p style={{color: "rgb(96, 108, 113)"}}>Analytics data may be shared as an anonymous aggregate to the public or other parties, for example we may choose to share how many users use a particular grid overlay in the app.</p>
            <p style={{color: "rgb(96, 108, 113)"}}>In the future, we may sell to, buy, merge with, or partner with other businesses. In such transactions, user information may be among the transferred assets.</p>
            <h3 style={{color: "#1699A2", marginBottom: 22, marginTop: 22}}>Contacting Us</h3>
            <p style={{color: "rgb(96, 108, 113)"}}>If you have questions regarding this privacy policy, you may email <a href="mailto:privacy@camrote.app">privacy@camrote.app</a>.</p>
            <h3 style={{color: "#1699A2", marginBottom: 22, marginTop: 22}}>Your Consent</h3>
            <p style={{color: "rgb(96, 108, 113)"}}>By using the Camrote app, you consent to our privacy policy.</p>
            <h3 style={{color: "#1699A2", marginBottom: 22, marginTop: 22}}>Changes</h3>
            <p style={{color: "rgb(96, 108, 113)"}}>If we decide to change our privacy policy, we will post those changes on this page.</p>
        </Container>
      </div>
    );
  }
}

export default App;

import React from 'react';
import PropTypes from "prop-types";

class Feature extends React.Component {

  constructor(props) {
    super(props);
    this.renderImage = this.renderImage.bind(this);
    this.renderText = this.renderText.bind(this);
  }

  renderImage() {
    return (
      <img className={"screenshot"} alt={"App Screenshot"} src={this.props.image}/>
    )
  }

  renderText() {
    return (
      <div>
        <h3 style={{color: this.props.tintColor, marginTop:  44}}>{this.props.name}</h3>
        <p style={{color: this.props.tintColor}}>{this.props.description}</p>
      </div>
    )
  }

  render() {
    return (
      <div className="feature">
        {this.renderImage()}
        {this.renderText()}
      </div>
    )
  }
}

Feature.defaultProps = {
  tintColor: "#ffffff"
}

Feature.propTypes = {

  /**
   * The src for the image
   */
  image: PropTypes.any,

  /**
   * The description of the feature
   */
  subtitle: PropTypes.string.isRequired,

  /**
   * The name of the feature
   */
  name: PropTypes.string.isRequired,

  /**
   * The colour for tinting elements such as text
   */
  tintColor: PropTypes.string
}

export default Feature;

import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Header from "../components/Header";

import { Link } from 'react-router-dom';
import Footer from "../components/Footer";

class App extends React.Component {

  render() {
    return (
      <div>
        <Header history={this.props.routerProps.history} title={"Support"}/>
        <Container style={{"padding": 44}}>
          <h3 style={{color: "#1699A2", marginBottom: 22}}>Known Issues</h3>
          <Alert style={{marginBottom: 44}} variant={"danger"}>
            <Alert.Heading>⍺7iii/a7riii crash</Alert.Heading> 
            <p style={{marginBottom: 0}}>There seems to be a sporadic crash on the a7iii/a7riii when changing exposure settings, we are looking into the cause of this crash.</p>
          </Alert>
          <Alert style={{marginBottom: 44}} variant={"warning"}>
            <Alert.Heading>RX0 Issues</Alert.Heading> 
            <p style={{marginBottom: 0}}>There seems to be an issue with some users connecting to the RX0, we are struggling to debug this due to lack of access to the device, but will continue to look into it with beta testers.</p>
          </Alert>
          <h3 style={{color: "#1699A2", marginBottom: 22}}>Supported Cameras</h3>
          <p style={{color: "rgb(96, 108, 113)"}}><Link to="/camera-support">Click here</Link> for a list of supported cameras</p>
          <h3 style={{color: "#1699A2", marginBottom: 22}}>Sony ⍺7iii/⍺7riii/⍺9/⍺7riv users</h3>
          <p style={{color: "rgb(96, 108, 113)"}}>You may notice that some of the functionality is either broken or completely missing on your device. Unfortunately this is due to entirely undocumented (And in some cases un-fixable) changes to the way the camera control works from the camera’s end. There are workarounds to some of these issues which are listed below:</p>
            <h5 style={{color: "#1699A2"}}>RAW Transfer</h5>
            <p style={{color: "rgb(96, 108, 113)"}}>Unfortunately RAW transfer view "Send to Smartphone" was removed on these models. Sony now supports FTP tethering for immediate transfer of RAW photos, and this is something we will be implementing in the future.</p>
          <h5 style={{color: "#1699A2"}}>Can’t take an image</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>We have attempted a fix for this issue in v1.2.2 of Camrote, but if you are still seeing issues please try one of the below:</p>
          <ul>
            <li style={{color: "rgb(96, 108, 113)"}}>Switch your camera to Manual Focus from the camera’s menu.</li>
            <li style={{color: "rgb(96, 108, 113)"}}>Half-press the shutter button to get focus before fully pressing (Using force-touch).</li>
            <li style={{color: "rgb(96, 108, 113)"}}>Turn "AF w/shutter" off</li>
            <li style={{color: "rgb(96, 108, 113)"}}>Shoot with a non-zero delay using the timer functionality.</li>
          </ul>
          <h5 style={{color: "#1699A2"}}>Touch to AF</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>Unfortunately Sony made the decision to drop support for Touch AF — as I’m sure many of you are aware — in their latest series of cameras, including the ⍺7iii, ⍺7riii and ⍺9.</p>
          <p style={{color: "rgb(96, 108, 113)"}}>Because this limitation is caused by the software running on the Camera and not the PlayMemories app on your phone, this feature will also not work using Camrote, and we will not be able to work around this until Sony re-instate it in a firmware update.</p>
          <p style={{color: "rgb(96, 108, 113)"}}>You can still achieve focus by performing a “half-press” of the shutter button like you would on the camera itself, by force-touching the shutter button on your iOS device.</p>
          <h5 style={{color: "#1699A2"}}>Focus Frames</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>Sony have also unfortunately dropped support for sending focus frames (Boxes representing in-focus areas of the image or focus points set on-camera) from the camera via the remote control API.</p>
          <h3 style={{color: "#1699A2", marginTop: 44, marginBottom: 22}}>Feature Information</h3>
          <h5 style={{color: "#1699A2"}}>Connecting To Camera</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>Sometimes you may experience issues with connecting to your camera for various reasons. There are some basic remedial steps you can take to try and resolve this:</p>
          <ol>
            <li style={{color: "rgb(96, 108, 113)"}}>Power off your camera and mobile device (Sometimes it is required to remove the camera’s battery).</li>
            <li style={{color: "rgb(96, 108, 113)"}}>Reset the network settings on your camera.</li>
            <li style={{color: "rgb(96, 108, 113)"}}>Make sure your camera’s firmware is fully up to date.</li>
          </ol>
          <p style={{color: "rgb(96, 108, 113)"}}>Below are some specific issues and your best steps to try and resolve them.</p>
          <h5 style={{color: "#1699A2"}}>“Cannot Modify System Configuration”</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>This can be caused due to a configuration profile that is interfering with our ability to automatically connect to your Camera’s WiFi. Follow the steps below to try and resolve this.</p>
          <ol>
            <li style={{color: "rgb(96, 108, 113)"}}>Go to your device’s Settings app.</li>
            <li style={{color: "rgb(96, 108, 113)"}}>Go to General → Profiles and Device Management.</li>
            <li style={{color: "rgb(96, 108, 113)"}}>Delete any profiles relating to your Sony Camera.</li>
            <li style={{color: "rgb(96, 108, 113)"}}>Try and connect again.</li>
          </ol>
          <h5 style={{color: "#1699A2"}}>Multi Camera Control</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>Some cameras have a Multi Camera Control functionality, if this is enabled then we won’t be able to connect to the WiFi on your camera.</p>
          <h5 style={{color: "#1699A2"}}>Timelapse</h5>
          <h5 style={{color: "#1699A2"}}>Sony ⍺7iii/⍺7riii/⍺9 users</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>If you are receiving an error: “actTakePicture: Shooting Failed” this is caused because your camera unfortunately has a bug which means it must be pre-focussed before my app can take a shot.</p>
          <p style={{color: "rgb(96, 108, 113)"}}>The best option here unfortunately is to shoot in Manual Focus whilst running timelapses, you can change this (for now) on the cameras side using the menu. Support for changing this via Camrote will be coming in an update soon!</p>
          <p style={{color: "rgb(96, 108, 113)"}}>This requirement is due to changes Sony have made to the behaviour of focussing on the newer bodies, which unfortunately during Timelapse shooting are difficult to work around without delaying frames.</p>
          <h5 style={{color: "#1699A2"}}>Image Stutter</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>Please try reducing the Image Size, or increasing the interval between captures! The captured Image Size can be found in the “Smart Remote Control” app on your camera whilst connected; in the second page of the menu.</p>
          <h5 style={{color: "#1699A2"}}>Other Issues</h5>
          <p style={{color: "rgb(96, 108, 113)"}}>Seeing other issues with Timelapse? Please use the email at the bottom of this page to contact us and we will look into it ASAP.</p>
          <h3 style={{color: "#1699A2", marginBottom: 22, marginTop: 22}}>Seeing other issues?</h3>
          <p style={{color: "rgb(96, 108, 113)"}}>Please <Link to="/contact">contact us</Link> for further support.</p>
        </Container>
        <Footer/>
      </div>
    );
  }
}

export default App;

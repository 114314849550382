 import React from 'react';
import logo from '../assets/logo.svg';
import roccLogo from "../assets/rocc-logo.svg";
import mainScreenshot from "../assets/screenshot-main.png";
import controlScreenshot from "../assets/screenshot-control.png";
import transferScreenshot from "../assets/screenshot-transfer.png";
import timelapseScreenshot from "../assets/screenshot-timelapse.png";
import githubLogo from "../assets/github-logo.svg";

import FeatureSection from "../components/FeatureSection";
import Section from "../components/Section";
import Header from "../components/Header";
import Review from "../components/Review";
import Feature from "../components/Feature";
import Footer from "../components/Footer";

import Row from 'react-bootstrap/Row'; 
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Link } from 'react-router-dom';

const CAPTURES = 2200000;
const DOWNLOADS = 150000;
const DISCOVERED = 1723000;
const TRANSFERS = 9010000;

class App extends React.Component {

  constructor(props) {
    super(props);
    this.onDownloadClicked = this.onDownloadClicked.bind(this);
    this.onOpenROCCClicked = this.onOpenROCCClicked.bind(this);
    const allReviews = [
      {
        stars: 5,
        reviewer: "Olivia Roseanna",
        date: "May 9 2020",
        title: "Perfect For Self Portraits With Sony A7iii",
        body: "I am using this app for helping to take self portraits with my Sony A7iii. I tried the sony playmemories app first but found it wasn’t a great user experience. This one is so much simpler and a much cleaner design. Being able to control the shutter remotely has made my photography process so much faster and It’s so helpful to be able to see the viewfinder view on my phone. Thank you so much for creating such a useful app!"
      },
      {
        stars: 5,
        reviewer: "Nick Apejido",
        date: "May 19 2020",
        title: "Works with a6000",
        body: "I thought I’d try this. Impressed right out of the box. Of course it didn’t come in a box. That’s just an allegory. But I could see the image and use the most important camera controls on my iPhone. And it was very smooth. Slight delay in triggering shutter. Hey you should carefully frame your shot anyway, eh? I know this will be excellent for some video work I want to do. Thank you!"
      },
      {
        stars: 5,
        reviewer: "carmelo film",
        date: "May 25 2020",
        title: "Wow it works!",
        body: "I own a Sony A73, It’s been a huge struggle to transfer 1 single photo through Sony Play Memories. This app sends multiple images in seconds! Glad to have found this one."
      },
      {
        stars: 5,
        reviewer: "Jim from BL",
        date: "May 27 2020",
        title: "It works 👍👍👍",
        body:
          "I have spent hours and hours trying to get my old Sony HX400v to connect to the Sony app with limited connectivity - having to add it as a new camera every time. This just works - no fuss no problems just works - 2 minutes to download - 30 seconds to set up and away we go 😁😁😁😁",
      },
      {
        stars: 5,
        reviewer: "WayTooTyler",
        date: "Oct 20 2019",
        title: "Better than the original!",
        body:
          "Love this app. I was sorely disappointed when I got my camera and downloaded the native Sony app to transfer my photos in bulk. It’s so slow and in user friendly. Plus no NFC SUPPORT?? Glad this app came to the rescue. I’ve completely eliminated the native app off my phone because this app is so much better. Well worth the purchase.",
        isZoomControl: false,
      },
      {
        stars: 5,
        reviewer: "仕事が嫌！！！！！！",
        date: "Jul 3 2019",
        title: "Love it",
        body:
          "I am a SonyA7iii user. I travel alone time to time.  It saved me lots of time and effort to take good pictures. I can take pictures exactly how I want them. Not to mention, it is free to download. There are addition options for upgrading for a few bucks. However it is great to use as is. I chose to pay him coffee, which is less than 4 dollar. It is not an obligation just an option you can do for the developer. Thanks developer for making such a great app! Mahalo!",
        isZoomControl: false,
      },
      {
        stars: 5,
        reviewer: "Tonycho",
        date: "May 20 2019",
        title: "Stable connection",
        body:
          "I know this apps not yet completed with many features, but it fits my need. \n\nI am architecture photographer who need stable connection for live view using my manual TS lens. Big live view, intuitive button and histogram is perfect for now. Keep going with the update 😄 Thank you",
        isZoomControl: false,
      },
      {
        stars: 4,
        reviewer: "NorseG0d",
        date: "Feb 2 2019",
        title: "Good little app",
        body:
          "Good app for my Sony AX53 camcorder but can’t seem to find a zoom option*. Is this going to be added? Or do I have to stick with Sony’s app for zoom ability",
        isZoomControl: true,
      },
      {
        stars: 4,
        reviewer: "Sebascelis",
        date: "Jan 11 2019",
        title: "Excellent app - I see a LOT of potential",
        body:
          "I love the timelapse feature and the manual controls are amazing. \n\nI would love to be able to choose the focus point, or change the focus in any way from the app. Also, if the feed could be a bit higher quality, it would be great, though I guess there are limitations dictated by the camera itself.",
        isZoomControl: false,
      },
      {
        stars: 4,
        reviewer: "enemene",
        date: "May 28 2019",
        title: "Nice app for remote automation",
        body:
          "Establishing 1st connection to an ILCE-5100 took a while, but then control and communication between iPad (mini 4) was stable. Only major functionality missing is zoom control*, thus at the moment I still prefer using Sony‘s Imaging Edge Mobile app in certain shooting situations.",
        isZoomControl: true,
      },
      {
        stars: 5,
        reviewer: "hege 88",
        date: "Feb 24, 2020",
        title: "Super ⭐️⭐️⭐️⭐️⭐️",
        body:
          "Endelig fant jeg en app som fungerer med iphone X og med mitt nye DSC-HX400V 👌🏻💯 kobla seg til selv og førte over bilder på ett blunk 😁👌🏻",
        isZoomControl: false,
      },
      {
        stars: 5,
        reviewer: "hannabananahanni",
        date: "Mar 10, 2020",
        title: "The best one out there",
        body: "Only app for remote control on my sony that actually works!",
      },
    ];
    this.state = {
      reviews: this.shuffle(allReviews).slice(0, 3)
    }
  }

  shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  onDownloadClicked() {
    window.open("https://itunes.apple.com/us/app/camrote/id1408732788?mt=8", "_blank")
  }

  onOpenROCCClicked() {
    window.open("https://github.com/simonmitchell/rocc", "_blank")
  }

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return (
      <div className="App">
        <Header history={this.props.routerProps.history} title={"Camrote"}/>
        <FeatureSection
          backgroundColor={"#36D1DC"}
          buttons={[
            {
              href: "https://itunes.apple.com/us/app/camrote/id1408732788?mt=8",
              title: "Download the App",
              variant: "outline-light"
            }
          ]}
          icon={logo}
          image={mainScreenshot}
          showDownIndicator={true}
          subtitle={"Simple remote control of Sony cameras"}
          tintColor={"white"}
          title={"Camrote"}
        />
        <Section 
          verticalPadding={88}
        > 
          <h2>{this.numberWithCommas(DOWNLOADS)} Downloads</h2>
          <h4> 
            {this.numberWithCommas(TRANSFERS)}+ Images Transferred<br/>
            {this.numberWithCommas(DISCOVERED)}+ Cameras Discovered<br/>
            {this.numberWithCommas(CAPTURES)}+ Images Captured<br/>
          </h4>
          <Row style={{marginTop: "54px"}}>
            {this.state.reviews.map((review, index) => {
              return (
                <Col key={index} className={"mb-4"} xs={12} md={6} lg={4}>
                  <Review {...review}/>
                </Col>
              );
            })}
          </Row>
          {!!this.state.reviews.find(review => review.isZoomControl) && (
            <p>*Zoom control support is now available in Camrote</p>
          )}
        </Section>
        <Section backgroundColor={"#36DCA6"}>
          <Row>
            <Col className={"mb-3"} xs={12} md={4}>
              <Feature
                name={"Control"}
                description={"Control and configure your Camera's primary functions in an intuitive UI."}
                image={controlScreenshot}
              />
            </Col>
            <Col className={"mb-3"} xs={12} md={4}>
              <Feature
                name={"Transfer"}
                description={"Transfer multiple original RAW files* quickly and effortlessly to your iOS device."}
                image={transferScreenshot}
              />
            </Col>
            <Col className={"mb-3"} xs={12} md={4}>
              <Feature
                name={"Timelapse"}
                description={"Capture images for creating amazing timelapse movies. †"}
                image={timelapseScreenshot}
              />
            </Col>
          </Row>
          <h3 style={{color: "#FFF", marginTop: 44, marginBottom: 22}}>Other Features</h3>
          <Row className="justify-content-md-center">
            <Col/>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>Zoom control</h5>
            </Col>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>Composition grids</h5>
            </Col>
            <Col/>
          </Row>
          <Row className="justify-content-md-center">
            <Col/>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>BULB capture</h5>
            </Col>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>BULB timelapse †</h5>
            </Col>
            <Col/>
          </Row>
          <Row className="justify-content-md-center">
            <Col/>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>Live view histogram</h5>
            </Col>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>Video capture</h5>
            </Col>
            <Col/>
          </Row>
          <Row className="justify-content-md-center">
            <Col/>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>Image previews</h5>
            </Col>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>Photo geotagging</h5>
            </Col>
            <Col/>
          </Row>
          <Row className="justify-content-md-center">
            <Col/>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>QR + NFC Scanning</h5>
            </Col>
            <Col xs={12} sm={5} lg={3}>
              <h5 style={{color: "#FFF"}}>Easy reconnect</h5>
            </Col>
            <Col/>
          </Row>
          <p style={{color: "#FFF", fontSize: 12, marginTop: 44}}>*RAW transfer isn't available on certain cameras. See the <Link style={{marginRight: 0, color: "white", textDecoration: "underline"}} to="/support">support</Link> page for more info.</p>
          <p style={{color: "#FFF", fontSize: 12}}>†Requires an In App Purchase.</p>
        </Section>
        <Section verticalPadding={80}>
          <img src={roccLogo}/>
          <p style={{marginTop: 44, marginBottom: 44}}>ROCC (Remote Camera Control) is a Swift framework for interacting with Digital Cameras which support function control or Image/Video transfer via a WiFi connection. It currently only supports control/transfer from Sony's line-up of cameras but will be expanding in the future to support as many manufacturers as possible!</p>
          <Button onClick={this.onOpenROCCClicked} variant={"dark"}><img style={{width: 30, height: 30, marginRight: 10}} src={githubLogo}/>View on GitHub</Button>
        </Section>
        <Footer/>
      </div>
    );
  }
}

export default App;

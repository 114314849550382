import React from 'react';
import { Link } from 'react-router-dom';

import twitterLogo from "../assets/logo-twitter.svg";
import facebookLogo from "../assets/logo-facebook.svg";
import instagramLogo from "../assets/logo-instagram.svg";

class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.onTwitterClick = this.onTwitterClick.bind(this);
    this.onFacebookClick = this.onFacebookClick.bind(this);
    this.onInstagramClick = this.onInstagramClick.bind(this);
  }

  onFacebookClick() {
    window.open("https://www.facebook.com/camroteapp/", "_blank");
  }

  onInstagramClick() {
    window.open("https://www.instagram.com/camrote/", "_blank");
  }

  onTwitterClick() {
    window.open("https://twitter.com/camroteapp", "_blank");
  }

  render() {
    return (
      <div className="footer">
        <div style={{backgroundColor: "#F6F6F6", padding: "22px 12px"}}>
          <p>© 2019 Simon Mitchell</p>
          <Link style={{color: "#000000"}} to={"privacy"}>Privacy</Link>
          <p>ɑ, NEX and RX are trademarks of Sony</p>
          <div style={{marginTop: 12}}>
            <img onClick={this.onTwitterClick} src={twitterLogo}/>
            <img style={{marginRight: 12, marginLeft: 8}} onClick={this.onFacebookClick} src={facebookLogo}/>
            <img onClick={this.onInstagramClick} src={instagramLogo}/>
          </div>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {

}

export default Footer;

import React from 'react';
import icon from "../assets/logo.svg";

import Row from 'react-bootstrap/Row'; 
import Col from 'react-bootstrap/Col';

import PropTypes from "prop-types";

import { Link } from 'react-router-dom';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.onIconClick = this.onIconClick.bind(this);
  }

  onIconClick() {
    this.props.history.push('/')
  }

  render() {
    return (
      <div className="header">
        <div style={{backgroundColor: "#36D1DC", padding: "22px 12px"}}>
          <Col xs={12}>
            <Row className="align-items-center" noGutters={true}>
              <img className={"icon-small"} onClick={this.onIconClick} src={icon}/>
              <Link to={"/about"} style={{color: "white"}}>About</Link>
              <Link to={"/contact"} style={{color: "white"}}>Contact</Link>
              <Link to={"/support"} style={{color: "white"}}>Support</Link>
              <a href={"https://itunes.apple.com/us/app/camrote/id1408732788?mt=8"} style={{color: "white"}}>Get the App</a>
            </Row>
          </Col>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  /**
   * The title of the section
   */
  title: PropTypes.string.isRequired
}

export default Header;

import React, { Component } from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Privacy from "./pages/Privacy";
import Support from "./pages/Support";
import Contact from "./pages/Contact";
import CameraSupport from "./pages/CameraSupport";

class App extends Component {

  render() {
    return (
      <div>
        <Route exact path={`/`} render={ (routerProps) => <Home routerProps={routerProps} />} />
        <Route exact path={process.env.PUBLIC_URL + `/about`} render={ (routerProps) => <About routerProps={routerProps} />} />
        <Route exact path={process.env.PUBLIC_URL + `/contact`} render={ (routerProps) => <Contact routerProps={routerProps} />} />
        <Route exact path={process.env.PUBLIC_URL + `/support`} render={ (routerProps) => <Support routerProps={routerProps} />} />
        <Route exact path={process.env.PUBLIC_URL + `/camera-support`} render={ (routerProps) => <CameraSupport routerProps={routerProps} />} />
        <Route exact path={process.env.PUBLIC_URL + `/privacy`} render={ (routerProps) => <Privacy routerProps={routerProps} />} />
      </div>
    );
  }
}

export default App;

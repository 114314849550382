import React from 'react';
import PropTypes from "prop-types";
import star from "../assets/star.svg";
import emptyStar from "../assets/star-empty.svg";

class Review extends React.Component {

  render() {
    return (
      <div className={"review"} style={{
        backgroundColor: '#F6F6F6', 
        padding: '15px 20px', 
        borderRadius: 14, 
        border: '1px solid #D6D6D6'
      }}>
        <div className="d-flex align-items-start">
          <h1 className="flex-shrink-1" style={{fontSize: 20}}>{this.props.title}</h1>
          <div className="d-flex flex-shrink-0 flex-grow-1 justify-content-end">
            <img alt={this.props.stars >= 1 ? "rating star" : "empty rating star"} className="rating-star" src={this.props.stars >= 1 ? star : emptyStar}/>
            <img alt={this.props.stars >= 2 ? "rating star" : "empty rating star"} className="rating-star" src={this.props.stars >= 2 ? star : emptyStar}/>
            <img alt={this.props.stars >= 3 ? "rating star" : "empty rating star"} className="rating-star" src={this.props.stars >= 3 ? star : emptyStar}/>
            <img alt={this.props.stars >= 4 ? "rating star" : "empty rating star"} className="rating-star" src={this.props.stars >= 4 ? star : emptyStar}/>
            <img alt={this.props.stars >= 5 ? "rating star" : "empty rating star"} className="rating-star" src={this.props.stars >= 5 ? star : emptyStar}/>
          </div>
        </div>
        <h4 style={{fontSize: 18}}>{this.props.reviewer} - {this.props.date}</h4>
        <p style={{fontSize: 18}}>{this.props.body}</p>
      </div>
    )
  }
}

Review.defaultProps = {
  
}

Review.propTypes = {

  /**
   * The body of the review
   */
  body: PropTypes.string.isRequired,

  /**
   * The date of the review
   */
  date: PropTypes.string.isRequired,

  /** 
   * The username of the reviewer
   */
  reviewer: PropTypes.string.isRequired,

  /**
   * The number of stars the reviewer gave
   */
  stars: PropTypes.number.isRequired,

  /**
   * The title of the review
   */
  title: PropTypes.string.isRequired
}

export default Review;
